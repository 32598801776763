import React, { Component } from "react";

import heroMb from '../../assets/img/Group 238.png'
import heroDt from '../../assets/img/Group 234.png'



class Hero extends Component {
    render() {
      return (
        <section id="hero">
            <div className="row mx-5">
                <div className="col-md-7 pr-8 text-banners my-auto">
                    <h1 className="text-white">
                        <p>
                            Dirumah aja dan dapat project bersama
                        </p>
                        <label>
                            Galaxy Code
                        </label>
                    </h1>
                    <label className="text-desc-header mb-5">Galaxy code adalah platform komunitas digital tempatnya pemuda yang ingin mencari project & mengembangkan skill mereka. Penasaran dengan galaxy code? yuk gabung sekarang!</label>
                    <a href="/">
                        <button className="btn btn--custom pad-but-custom">Gabung</button>
                    </a>
                </div>
                
                <div className="col-md-5 hidden-sm hidden-xs hidden-xs">
                    <img src={heroDt} className="img--section"/>
                </div>
                
                <div className="col-md-5 hidden-md hidden-lg">
                    <img src={heroMb} className="img--section"/>
                </div>
            </div>
</section>
      );
    }
  }

export default Hero
