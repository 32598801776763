import React, { Component } from "react";

import benefitDt from '../../assets/img/Group 200.png'
import benefitMb from '../../assets/img/Group 999.png'

import dirumahAja from '../../assets/img/Group 230.png'
import modalGratis from '../../assets/img/Group 231.png'
import kesempatanProject from '../../assets/img/Group 232.png'
import dapatPortfolio from '../../assets/img/Group 233.png'




class Benefit extends Component {
    render() {
      return (
        
    <section id="about" className="about">
        <div className="container">
            <div className="row content content-column hidden-md hidden-lg">
                <div className="col-lg-6 about-img">
                    <img src={benefitDt} width="100%" height="auto" className="d-none d-md-block" alt=""/>
                    <img src={benefitMb} width="100%" height="auto" className="d-block benefit-img d-md-none" alt=""/>
                </div>
                <div className="col-lg-6 mb-sm-10">
                    <h2 className="fw-700 text-blue-dark hidden-md hidden-lg">Dapatkan keuntungan dengan gabung galaxy code⚡</h2>
                    <h2 className="fw-700 text-blue-dark hidden-sm hidden-xs">Dapatkan keuntungan gabung galaxy code⚡</h2>
                    <p className="text-abu fs-17 sub-benefit">Bersantai dan diam dirumah biarkan galaxy code bekerja mencarikan project yang cocok untuk kamu.</p>
                    <div className="row mt-5">
                        <div className="col-lg-3 col-benefit-sm-3"><img src={dirumahAja} alt="" /></div>
                        <div className="col-lg-8 p-0 col-benefit-sm-9">
                            <h5 className="text-abu title-benefit">Dirumah Aja Dapet Kerja</h5>
                            <p className="text-abu text-desc-2">Dirumah tapi dapet peluang project atau kerjaan biarkan Galaxy Code bekerja.</p>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-3 col-benefit-sm-3"><img src={modalGratis} alt=""/></div>
                        <div className="col-lg-8 p-0 col-benefit-sm-9">
                            <h5 className="text-abu title-benefit">Tidak Perlu Keluar Modal Gratis</h5>
                            <p className="text-abu text-desc-2">Galaxy Code tidak akan memungut biaya sepeserpun dari member yang ingin bergabung.</p>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-3 col-benefit-sm-3"><img src={kesempatanProject} alt=""/></div>
                        <div className="col-lg-8 p-0 col-benefit-sm-9">
                            <h5 className="text-abu title-benefit">Kesempatan Project</h5>
                            <p className="text-abu text-desc-2">Bersama Galaxy Code peluang kamu untuk dapat project setiap bulannya semakin besar.</p>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-3 col-benefit-sm-3"><img src={dapatPortfolio} alt=""/></div>
                        <div className="col-lg-8 p-0 col-benefit-sm-9">
                            <h5 className="text-abu title-benefit">Mendapatkan Portofolio / Sertifikat</h5>
                            <p className="text-abu text-desc-2">Kesempatan mendapatkan portfolio dan sertifikat untuk modal masa depan yang lebih baik.</p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="row content content-column hidden-sm hidden-xs">
                <div className="col-lg-6 mb-sm-10">
                    <h2 className="fw-700 text-blue-dark hidden-md hidden-lg">Dapatkan keuntungan dengan gabung galaxy code⚡</h2>
                    <h2 className="fw-700 text-blue-dark hidden-sm hidden-xs">Dapatkan keuntungan gabung galaxy code⚡</h2>
                    <p className="text-abu fs-17 sub-benefit">Bersantai dan diam dirumah biarkan galaxy code bekerja mencarikan project yang cocok untuk kamu.</p>
                    <div className="row mt-5">
                        <div className="col-lg-3 col-benefit-sm-3"><img src={dirumahAja} alt=""/></div>
                        <div className="col-lg-8 p-0 col-benefit-sm-9">
                            <h5 className="text-abu title-benefit">Dirumah Aja Dapet Kerja</h5>
                            <p className="text-abu text-desc-2">Dirumah tapi dapet peluang project atau kerjaan biarkan Galaxy Code bekerja.</p>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-3 col-benefit-sm-3"><img src={modalGratis} alt=""/></div>
                        <div className="col-lg-8 p-0 col-benefit-sm-9">
                            <h5 className="text-abu title-benefit">Tidak Perlu Keluar Modal Gratis</h5>
                            <p className="text-abu text-desc-2">Galaxy Code tidak akan memungut biaya sepeserpun dari member yang ingin bergabung.</p>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-3 col-benefit-sm-3"><img src={kesempatanProject} alt=""/></div>
                        <div className="col-lg-8 p-0 col-benefit-sm-9">
                            <h5 className="text-abu title-benefit">Kesempatan Project</h5>
                            <p className="text-abu text-desc-2">Bersama Galaxy Code peluang kamu untuk dapat project setiap bulannya semakin besar.</p>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-3 col-benefit-sm-3"><img src={dapatPortfolio} alt=""/></div>
                        <div className="col-lg-8 p-0 col-benefit-sm-9">
                            <h5 className="text-abu title-benefit">Mendapatkan Portofolio / Sertifikat</h5>
                            <p className="text-abu text-desc-2">Kesempatan mendapatkan portfolio dan sertifikat untuk modal masa depan yang lebih baik.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 about-img">
                    <img src={benefitDt} width="100%" height="auto" className="d-none d-md-block" alt="" />
                    <img src={benefitMb} width="100%" height="auto" className="d-block benefit-img d-md-none" alt="" />
                </div>

            </div>

        </div>
    </section>
      );
    }
  }

export default Benefit