import React, { Component } from "react";





import AstraInternational from '../../assets/img/Mask Group 35.png'
import Schenider from '../../assets/img/schenider.png'
import PatraJasa from '../../assets/img/Mask Group 43.png'
import Toyota from '../../assets/img/Mask Group 44.png'
import Pama from '../../assets/img/Group 226.png'
import MandiriSyariah from '../../assets/img/Mask Group 45.png'
import Lkpp from '../../assets/img/Mask Group 39.png'




class Client extends Component {
    render() {
      return (
<section id="services" className="services mb-5">
    <div className="container mb-5">

        <div className="row my-5">
            <div className="col-lg-12 text-center mb-5 title-project-mb-sm">
                <h2 className="m-0 fw-700 text-blue-dark">Bekerja sama dengan Goodeva around project⚡</h2>
                <p className="text-abu fs-17 mt-2">Gabung dan dapatkan kesempatan untuk sebuah project</p>
            </div>
            <div className="col-lg-12 logo-project-mb-sm">
                <div className="row">
                    <div className="col-lg-3 col-6 my-auto">
                        <img className="img-fluid" src={AstraInternational} alt=""/>
                    </div>
                    <div className="col-lg-3 col-6 my-auto">
                        <img className="img-fluid" src={Schenider} alt=""/>
                    </div>
                    <div className="col-lg-3 col-6 my-auto">
                        <img className="img-fluid" src={PatraJasa} alt="" />
                    </div>
                    <div className="col-lg-3 col-6 my-auto">
                        <img className="img-fluid" src={Toyota} alt="" />
                    </div>
                    <div className="col-lg-3 col-6 my-auto">
                        <img className="img-fluid" src={Pama} alt="" />
                    </div>
                    <div className="col-lg-3 col-6 my-auto">
                        <img className="img-fluid" src={MandiriSyariah} alt="" />
                    </div>
                    <div className="col-lg-3 col-6 my-auto">
                        <img className="img-fluid" src={Lkpp} alt="" />
                    </div>
                    <div className="col-lg-3 col-6 my-auto">
                        <img className="img-fluid" src={AstraInternational} alt="" />
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>      
      );
    }
  }

export default Client