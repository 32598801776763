import React, { Component } from "react";

import logoWhite from '../../assets/img/GALAXY CODE putih.png'



class Footer extends Component {
    render() {
      return (
          
        <footer id="footer">
    <div className="footer-top">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 mb-5 mt-m160">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mx-2 my-2">
                                <div className="col-lg-8 col-sm-12 mb-3">
                                    <p className="m-0">Tertarik untuk <span className="hidden-sm hidden-xs">mendapatkan</span> <strong>Project</strong> bersama <strong>Galaxy Code</strong>?</p>
                                </div>
                                <div className="col-lg-4 col-sm-12 my-auto text-center">
                                    <a href="/">
                                        <button className="btn btn--gabung btn--gabungs mx-auto fw-500" >Gabung</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 mt-5 logo-putih">
                    <img src={logoWhite} alt=""/>
                </div>
            </div>
        </div>
    </div>

    <div className="container">
        <div className="copyright hidden-sm hidden-xs">
            COPYRIGHT © 2021 GALAXYCODE. <br/> ALL RIGHTS RESERVED.
        </div>
        <div className="copyright hidden-md hidden-lg">
            COPYRIGHT © 2021 GALAXYCODE. ALL RIGHTS RESERVED.
        </div>
        
    </div>
</footer>
      );
    }
  }

export default Footer

