import React, { Component } from "react";





import frontEnd from '../../assets/img/Group 201-1.png'
import backEnd from '../../assets/img/Group 201.png'
import mobile from '../../assets/img/Group 235.png'
import checkIcon from '../../assets/img/check-mark-button_2705.png'




class JoinProject extends Component {
    render() {
      return (
<div id="JoinProject">
<section id="clients" className="clients section-bg hidden-md hidden-lg">
        <div className="container">

            <div className="row my-5">
            <div className="col-lg-12 mb-5 mb-sm-0">
                <h2 className="m-0 fw-700 text-blue-dark title-need">Galaxy Code membutuhkan kamu⚡</h2>
                <p className="text-abu fs-17 mt-2">Gabung dan dapatkan kesempatan untuk sebuah project</p>
            </div>

            <div className="col-lg-12">
                <div className="row mt-4 mb-5 slide-galeri">

                    <div>

                        <div className="hoverable">
                            <div className="card mx-auto">
                                <div className="card-body text-left card-body-need">
                                    <div className="row">
                                        <div className="col-lg-3 col-need-sm-3 pr-0"><img src={frontEnd} className="cek-spek logo--need" width="55px" alt="" /></div>
                                        <div className="col-lg-8 col-need-sm-9 text-left my-auto">
                                            <p className="m-0">Programmer</p>
                                            <h4>Front End</h4>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="mb-1"><img src={checkIcon} alt="" width="20px" className="cek-spek mr-1" /> Memahami <strong>HTML</strong></div>
                                    <div className="mb-1"><img src={checkIcon} alt="" width="20px" className="cek-spek mr-1" /> Memahami <strong>Java Script</strong></div>
                                    <div className="mb-1"><img src={checkIcon} alt="" width="20px" className="cek-spek mr-1" /> Memahami <strong>React</strong></div>
                                </div>
                            </div>
                            <a href="/" className="btn btn--gabung--card mx-auto">Gabung Sekarang</a>
                        </div>

                    </div>

                    <div>

                        <div className="hoverable">
                            <div className="card mx-auto">
                                <div className="card-body text-left card-body-need">
                                    <div className="row">
                                        <div className="col-lg-3 col-need-sm-3 pr-0"><img src={backEnd} className="cek-spek logo--need" width="55px" alt=""/></div>
                                        <div className="col-lg-8 col-need-sm-9 text-left my-auto">
                                            <p className="m-0">Programmer</p>
                                            <h4>Back End</h4>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="mb-1"><img src={checkIcon} alt="" width="20px" className="cek-spek mr-1" /> Memahami <strong>Laravel</strong></div>
                                    <div className="mb-1"><img src={checkIcon} alt="" width="20px" className="cek-spek mr-1" /> Memahami <strong>Php Native</strong></div>
                                </div>
                            </div>
                            <a href="/" className="btn btn--gabung--card mx-auto">Gabung Sekarang</a>
                        </div>

                    </div>

                    <div>

                        <div className="hoverable">
                            <div className="card mx-auto">
                                <div className="card-body text-left card-body-need">
                                    <div className="row">
                                        <div className="col-lg-3 col-need-sm-3 pr-0"><img src={mobile} className="cek-spek logo--need" width="55px" alt=""/></div>
                                        <div className="col-lg-8 col-need-sm-9 text-left my-auto">
                                            <p className="m-0">Programmer</p>
                                            <h4>Mobile</h4>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="mb-1"><img src={checkIcon} alt="" width="20px" className="cek-spek mr-1" /> Memahami <strong>iOS</strong></div>
                                    <div className="mb-1"><img src={checkIcon} alt="" width="20px" className="cek-spek mr-1" /> Memahami <strong>Android</strong></div>
                                </div>
                            </div>
                            <a href="/" className="btn btn--gabung--card mx-auto">Daftar Kelas</a>
                        </div>

                    </div>

    

                </div>

            </div>

        </div>

    </div>
</section>

    
    <section id="clients-desktop" className="clients section-bg hidden-sm hidden-xs">
        <div className="container">

            <div className="row my-5">

            <div className="col-lg-12 mb-5">
                <h2 className="m-0 fw-700 text-blue-dark title-need">Galaxy Code membutuhkan kamu⚡</h2>
                <p className="text-abu fs-17 mt-2">Gabung dan dapatkan kesempatan untuk sebuah project</p>
            </div>

            <div className="col-lg-4">
                <div className="hoverable">
                    <div className="card mx-auto">
                        <div className="card-body text-left card-body-need">
                            <div className="row">
                                <div className="col-lg-3 pr-0"><img src={frontEnd} width="55px" alt=""/></div>
                                <div className="col-lg-8 text-left my-auto">
                                    <p className="m-0">Programmer</p>
                                    <h4>Front End</h4>
                                </div>
                            </div>
                            <hr/>
                            <div className="mb-1"><img src={checkIcon}  alt="" width="20px" className="mr-1" /> Memahami <strong>HTML</strong></div>
                            <div className="mb-1"><img src={checkIcon}  alt="" width="20px" className="mr-1" /> Memahami <strong>Java Script</strong></div>
                            <div className="mb-1"><img src={checkIcon}  alt="" width="20px" className="mr-1" /> Memahami <strong>React</strong></div>
                        </div>
                    </div>
                    <a href="{{ url('galaxy-code-register') }}" className="btn btn--gabung--card mx-auto">Gabung Sekarang</a>
                </div>
            </div>

            <div className="col-lg-4 box-need hoverable">
                <div className="card mx-auto">
                    <div className="card-body text-left card-body-need">
                        <div className="row">
                            <div className="col-lg-3 pr-0"><img src={backEnd} width="55px" alt="" /></div>
                            <div className="col-lg-8 text-left my-auto">
                                <p className="m-0">Programmer</p>
                                <h4>Back End</h4>
                            </div>
                        </div>
                        <hr/>
                        <div className="mb-1"><img src={checkIcon} alt="" width="20px" className="mr-1" /> Memahami <strong>Laravel</strong></div>
                        <div className="mb-1"><img src={checkIcon} alt="" width="20px" className="mr-1" /> Memahami <strong>Php Native</strong></div>
                    </div>
                </div>
                <a href="{{ url('galaxy-code-register') }}" className="btn btn--gabung--card mx-auto">Gabung Sekarang</a>
            </div>

            <div className="col-lg-4 box-need hoverable">
                <div className="card mx-auto">
                    <div className="card-body text-left card-body-need">
                        <div className="row">
                            <div className="col-lg-3 pr-0"><img src={mobile} width="55px" alt="" /></div>
                            <div className="col-lg-8 text-left my-auto">
                                <p className="m-0">Programmer</p>
                                <h4>Mobile</h4>
                            </div>
                        </div>
                        <hr/>
                        <div className="mb-1"><img src={checkIcon}  alt="" width="20px" className="mr-1" /> Memahami <strong>iOS</strong></div>
                        <div className="mb-1"><img src={checkIcon}  alt="" width="20px" className="mr-1" /> Memahami <strong>Android</strong></div>
                    </div>
                </div>
                <a href="/" className="btn btn--gabung--card mx-auto">Daftar Kelas</a>
            </div>

        </div>

    </div>
</section>
</div>        
      );
    }
  }

export default JoinProject