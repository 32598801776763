import React, { Component } from 'react'
// import React, { Component } from "react"
import Navbar from '../components/Navbar'
import Hero from '../components/Hero'
import Benefit from '../components/Benefit'
import JoinProject from '../components/JoinProject'
import Client from '../components/Client'
import Footer from '../components/Footer'

// import "../assets/css/codebase.min.css";
import "../assets/template-front/vendor/bootstrap/css/bootstrap.min.css"
import "../assets/template-front/vendor/icofont/icofont.min.css"
import "../assets/template-front/vendor/boxicons/css/boxicons.min.css"
import "../assets/template-front/vendor/animate.css/animate.min.css"
import "../assets/template-front/vendor/remixicon/remixicon.css"
import "../assets/template-front/vendor/venobox/venobox.css"
import "../assets/template-front/vendor/owl.carousel/assets/owl.carousel.min.css"
import "../assets/template-front/css/slick.css"
import "../assets/template-front/css/slick-theme.css"
import "../assets/css/style-custom.css"
import "../assets/template-front/css/style.css?v=1"
import "../style.css"



import {
  BrowserRouter as Router,
  Routes,
  NavLink,
  Route
} from "react-router-dom";

import 'boxicons';
import scrollTo from 'gatsby-plugin-smoothscroll';


function Main() {
  return (
    <>
    <Router>
      
        <Navbar/>
        <Hero/>

        <main id="main">
          <Benefit/>
          <JoinProject/>
          <Client/>
        </main>

        
        <Footer/>

        <button className="btn btn-danger back-to-top" onClick={() => scrollTo('#hero')}>
            <box-icon name="chevron-up"  type="solid" color="#FFFFFF"></box-icon>
        </button>
    
    </Router>
    
    
    
    </>
  );
}




export default Main
