import React, { Component } from "react";

import logoPrim from '../../assets/img/galaxy-code.png'



class Navbar extends Component {
    render() {
      return (
          
        <header id="header"  className="fixed-top align-items-center p-0">
        <div className="container d-flex align-items-center">

            <a href="/" className="logo me-auto">
                <img src={logoPrim}  className="img-logo"/>
            </a>

            <nav className="nav-menu d-none d-lg-block align-items-center">
                <ul>
                    <li><span className="blue--text">Cari Peluang Project?</span></li>
                    <li>
                        <div className="">
                            <a href="#" id="btn_gabung_head" className="btn btn--gabung mx-auto">Gabung</a>
                        </div>

                    </li>
                </ul>
            </nav>
        </div>
    </header>
      );
    }
  }

export default Navbar
